import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Layout from '../components/Layout'
import BannerCards from '../components/BannerCards'
import SEO from '../components/seo'

const ContactPageTemplate = ({
  image,
  bgimage,
  bannerinfo,
  contactdetails,
  seo,
}) => {
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (!initialized) {
      setInitialized(true)

      var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js')

      mapboxgl.accessToken =
        'pk.eyJ1IjoiZGJlZWxlciIsImEiOiJjanhjYjZrY28wMW0zM25vMDB2NzB2NWp2In0.yHP1VvWVmfCjoXu3_iw6Xw'
      var map = new mapboxgl.Map({
        container: 'google-map',
        style: 'mapbox://styles/mapbox/streets-v11',
        zoom: 13,
        center: [-84.119957, 39.751933],
      })

      new mapboxgl.Marker().setLngLat([-84.1193, 39.751]).addTo(map)
    }
  })

  return (
    <div className="">
      {seo || ''}
      <div className="banner-image--container banner-image--container--short">
        <Img
          className="full-width-image margin-top-0 banner-image banner-image--shorter"
          fluid={bgimage}
          backgroundColor={`#1d3d6d`}
        />
      </div>
      <div className="content-background">
        <div className="banner-card__container banner-card__container--short">
          <BannerCards rowItems={bannerinfo.cards} className={''} />
        </div>

        <section className="section section--gradient section--short section--contact">
          <div className="container">
            <div className="card card-content content-background--card">
              <div className="content">
                <div className="columns is-desktop">
                  <div className="column is-4-desktop is-12-mobile">
                    <div className="info-block">
                      <p className="is-size-5">
                        <span className="has-text-weight-bold">
                          {contactdetails.headquarters.heading}
                        </span>
                        <br />
                        {contactdetails.headquarters.street}
                        <br />
                        {contactdetails.headquarters.citystatezip}
                        <br />
                        <a
                          href={'tel:' + contactdetails.headquarters.office}
                          className="is-size-5 has-nowrap"
                        >
                          <FontAwesomeIcon
                            className="fa-icon"
                            size="xs"
                            icon="phone-alt"
                          />
                          {contactdetails.headquarters.office}
                        </a>
                        <br />
                      </p>
                    </div>
                    {contactdetails.pocs.map(poc => (
                      <div className="info-block" key={poc.name}>
                        <p className="is-size-5">
                          <span className="has-text-weight-bold">
                            {poc.heading}
                          </span>
                          <br />
                          {poc.name}
                          <br />
                          {poc.title}
                          <br />
                          <a
                            href={'tel:' + poc.phone}
                            className="is-size-5 has-nowrap"
                          >
                            <FontAwesomeIcon
                              className="fa-icon"
                              size="xs"
                              icon="phone-alt"
                            />
                            {poc.phone}
                          </a>
                          <br />
                          <a href={'mailto:' + poc.email} className="is-size-5">
                            <FontAwesomeIcon
                              className="fa-icon"
                              size="xs"
                              icon="envelope"
                            />
                            {poc.email}
                          </a>
                        </p>
                      </div>
                    ))}
                  </div>

                  <div className="column is-8-desktop is-12-mobile">
                    <div id="google-map" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

ContactPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  bannerinfo: PropTypes.shape({
    cards: PropTypes.array,
  }),
  contactdetails: PropTypes.shape({
    headquarters: PropTypes.shape({
      heading: PropTypes.string,
      street: PropTypes.string,
      citystatezip: PropTypes.string,
      office: PropTypes.string,
    }),
    pocs: PropTypes.array,
  }),
  seo: PropTypes.object,
}

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ContactPageTemplate
        image={frontmatter.image}
        bgimage={data.desktop.childImageSharp.fluid}
        bannerinfo={frontmatter.bannerinfo}
        contactdetails={frontmatter.contactdetails}
        seo={
          <SEO
            title={`Contact | ${data.site.siteMetadata.title}`}
            description={`${data.site.siteMetadata.contactDescription}`}
            type="website"
          />
        }
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.object,
    }),
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPageTemplate {
    desktop: file(relativePath: { eq: "contact-hero.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        author
        title
        description
        homeDescription
        contractVehiclesDescription
        newsDescription
        contactDescription
        siteUrl
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        image
        bannerinfo {
          cards {
            headline
            text
          }
        }
        contactdetails {
          headquarters {
            heading
            street
            citystatezip
            office
          }
          pocs {
            heading
            name
            title
            phone
            email
          }
        }
      }
    }
  }
`
