// Ref: https://blog.dustinschau.com/search-engine-optimization-with-gatsby

import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const SEO = ({
  description,
  image: metaImage,
  title,
  type,
  article,
  dateCreated,
  dateModified,
  slug,
}) => {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            buildTime(formatString: "YYYY-MM-DD")
            siteMetadata {
              siteUrl
              defaultTitle: title
              legalName
              defaultDescription: description
              defaultBanner: banner
              siteLanguage
              ogLanguage
            }
          }
        }
      `}
      render={data => {
        const {
          siteMetadata: {
            siteUrl,
            defaultTitle,
            legalName,
            defaultDescription,
            defaultBanner,
            siteLanguage,
            ogLanguage,
          },
        } = data.site

        const metaDescription = description || defaultDescription
        const metaType = type || 'article'
        const image =
          metaImage && metaImage.src
            ? `${siteUrl}${metaImage.src}`
            : `${siteUrl}/img/og-image.jpg`
        // const metaImage = image || `${url}/img/og-image.jpg`;

        const imgWidth = metaImage && metaImage.src ? '600' : '1200'
        const imgHeight = metaImage && metaImage.src ? '300' : '630'

        const seo = {
          name: defaultTitle,
          title: title || defaultTitle,
          description: description || defaultDescription,
          image:
            metaImage && metaImage.src
              ? `${siteUrl}${metaImage.src}`
              : `${siteUrl}/img/og-image.jpg`,
          url: `${siteUrl}`,
          datePublished: '2019-01-18T10:30:00+01:00',
          idOrganization: `${siteUrl}/#organization`,
          idWebSite: `${siteUrl}/#website`,
          idWebPage: `${siteUrl}/#webpage`,
          idLogo: `${siteUrl}/#logo`,
        }

        // schema.org in JSONLD format
        // https://developers.google.com/search/docs/guides/intro-structured-data

        const schemaOrganization = {
          '@type': 'Organization',
          '@id': seo.idOrganization,
          name: seo.name,
          legalName: legalName,
          url: seo.url,
          logo: {
            '@type': 'ImageObject',
            '@id': seo.idLogo,
            url: `${siteUrl}${defaultBanner}`,
            width: '360',
            height: '216',
            caption: seo.name,
          },
          image: {
            '@id': seo.idLogo,
          },
          address: {
            '@type': 'PostalAddress',
            '@id': `${seo.url}/#address`,
            streetAddress: '1020 Woodman Drive',
            addressLocality: 'Dayton',
            addressRegion: 'OH',
            postalCode: '45432',
            addressCountry: 'USA',
          },
        }

        const schemaWebSite = {
          '@type': 'WebSite',
          '@id': seo.idWebSite,
          name: seo.name,
          url: seo.url,
          author: {
            '@id': seo.idOrganization,
          },
          copyrightHolder: {
            '@id': seo.idOrganization,
          },
          copyrightYear: '2019',
          creator: {
            '@id': seo.idOrganization,
          },
          publisher: {
            '@id': seo.idOrganization,
          },
          image: {
            '@id': seo.idLogo,
          },
        }

        const schemaOrgWebPage = {
          '@context': 'http://schema.org',
          '@graph': [
            schemaOrganization,
            schemaWebSite,
            {
              '@type': 'WebPage',
              '@id': seo.idWebPage,
              name: seo.name,
              url: seo.url,
              inLanguage: ogLanguage,
              mainEntityOfPage: seo.url,
              isPartOf: {
                '@id': seo.idWebSite,
              },
              about: {
                '@id': seo.idOrganization,
              },
              datePublished: seo.datePublished,
              dateModified: dateModified,
              dateCreated: dateCreated,
              description: seo.description,
              author: {
                '@id': seo.idOrganization,
              },
              copyrightHolder: {
                '@id': seo.idOrganization,
              },
              copyrightYear: '2019',
              creator: {
                '@id': seo.idOrganization,
              },
              publisher: {
                '@id': seo.idOrganization,
              },
              image: {
                '@id': seo.idLogo,
              },
            },
          ],
        }

        // Initial breadcrumb list

        const itemListElement = [
          {
            '@type': 'ListItem',
            item: {
              '@id': siteUrl,
              name: 'Homepage',
            },
            position: 1,
          },
        ]

        // Form schema for Articles

        let schemaArticle = null

        if (article) {
          schemaArticle = {
            '@context': 'http://schema.org',
            '@graph': [
              schemaOrganization,
              schemaWebSite,
              {
                '@type': 'WebPage',
                '@id': seo.idWebPage,
                name: seo.title,
                url: seo.url,
                inLanguage: ogLanguage,
                isPartOf: {
                  '@id': seo.idWebSite,
                },
                about: {
                  '@id': seo.idOrganization,
                },
                datePublished: seo.datePublished,
                dateModified: dateModified,
                dateCreated: dateCreated,
                description: seo.description,
                author: {
                  '@id': seo.idOrganization,
                },
                copyrightHolder: {
                  '@id': seo.idOrganization,
                },
                copyrightYear: '2019',
                creator: {
                  '@id': seo.idOrganization,
                },
                publisher: {
                  '@id': seo.idOrganization,
                },
                image: {
                  '@type': 'ImageObject',
                  '@id': `${seo.url}/#primaryimage`,
                  url: image,
                  width: imgWidth,
                  height: imgHeight,
                  // caption
                },
                primaryImageOfPage: {
                  '@id': `${seo.url}/#primaryimage`,
                },
              },
              {
                '@type': 'Article',
                '@id': `${seo.url}/#article`,
                headline: seo.title,
                datePublished: seo.datePublished,
                dateModified: dateModified,
                dateCreated: dateCreated,
                description: seo.description,
                commentCount: '0',
                articleSection: 'news',
                mainEntityOfPage: `${seo.url}${slug}`,
                isPartOf: {
                  '@id': seo.idWebPage,
                },
                author: {
                  '@id': seo.idOrganization,
                },
                copyrightHolder: {
                  '@id': seo.idOrganization,
                },
                copyrightYear: '2019',
                creator: {
                  '@id': seo.idOrganization,
                },
                publisher: {
                  '@id': seo.idOrganization,
                },
                image: {
                  '@type': 'ImageObject',
                  '@id': `${seo.url}/#primaryimage`,
                  url: image,
                  width: imgWidth,
                  height: imgHeight,
                  // caption
                },
              },
            ],
          }

          // Push current blogpost into breadcrumb list
          itemListElement.push({
            '@type': 'ListItem',
            item: {
              '@id': `${seo.url}${slug}`,
              name: seo.title,
            },
            position: 2,
          })
        }

        const breadcrumb = {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          description: 'Breadcrumbs list',
          name: 'Breadcrumbs',
          itemListElement,
        }

        return (
          <div>
            <Helmet title={title}>
              <html lang={siteLanguage} />
              <meta name="description" content={metaDescription} />
              <meta name="og:locale" content={ogLanguage} />
              <meta name="og:type" content={metaType} />
              {article && (
                <meta
                  name="og:type:published_time"
                  content={seo.datePublished}
                />
              )}
              {article && (
                <meta name="og:type:modified_time" content={dateModified} />
              )}
              {article && (
                <meta name="og:type:section" content="Business News" />
              )}
              <meta name="og:title" content={title} />
              <meta name="og:description" content={metaDescription} />
              <meta name="og:site_name" content={seo.name} />
              <meta name="og:url" content={data.site.siteMetadata.siteUrl} />
              <meta name="og:image" content={image} />
              <meta name="og:image:width" content={imgWidth} />
              <meta name="og:image:height" content={imgHeight} />
              <meta name="twitter:creator" content={seo.name} />
              <meta name="twitter:title" content={title} />
              <meta name="twitter:description" content={metaDescription} />
              <meta name="twitter:image" content={image} />
              <meta name="twitter:card" content="summary_large_image" />

              {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
              {!article && (
                <script type="application/ld+json">
                  {JSON.stringify(schemaOrgWebPage)}
                </script>
              )}
              {article && (
                <script type="application/ld+json">
                  {JSON.stringify(schemaArticle)}
                </script>
              )}
              <script type="application/ld+json">
                {JSON.stringify(breadcrumb)}
              </script>
            </Helmet>
          </div>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  title: null,
  description: null,
  article: false,
  dateCreated: null,
  dateModified: null,
  slug: null,
  image: null,
  type: null,
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  article: PropTypes.bool,
  dateCreated: PropTypes.string,
  dateModified: PropTypes.string,
  slug: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
  }),
  type: PropTypes.string,
}

export default SEO
